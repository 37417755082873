import React from "react";
import { Link } from "react-router-dom";

import Navlight from "../components/navlight";
import Features from "../components/features";
import Clients from "../components/clients";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

export default function Services(){
   return(
    <>
    <Navlight/>
    <section className="relative table w-full py-32 lg:py-40 bg-[url('../../assets/images/portfolio/bg-inner.jpg')] bg-no-repeat bg-top bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div className="container relative">
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
                <h3 className="text-3xl leading-normal font-medium text-white">Our Services</h3>
            </div>
        </div>
        
        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
            <ul className="tracking-[0.5px] mb-0 inline-block">
                <li className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Muvico</Link></li>
                <li className="inline-block text-[18px] text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right align-middle"></i></li>
                <li className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white" aria-current="page">Services</li>
            </ul>
        </div>
    </section>
    <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
            <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <section className="relative md:pb-24 pb-16">
        <Features title={false}/>
        <Clients/>
        <ContactUs/>
    </section>
    <Footer/>
    <Switcher/>
    </>
   )
}