import React from "react";
import { Link } from "react-router-dom";

import team1 from '../assets/images/team/01.jpg'
import team2 from '../assets/images/team/02.jpg'
import team3 from '../assets/images/team/03.jpg'
import team4 from '../assets/images/team/04.jpg'
import team5 from '../assets/images/team/05.jpg'
import team6 from '../assets/images/team/06.jpg'
export default function Clients(){

    const teamData = [
        {
            image:team1,
            name:'Kawsar Ahmed',
            title:'C.E.O ( Cari de linda-Indian restaurant|| Québec,QC G1H 2S9)',
            desc:"Learnwithmurad team did an amazing job designing my restaurant menu and also upload products gallery in google map. They were easy to communicate with, and their attention to detail was impressive. I highly recommend them!",
            image2:team2,
            name2:'Masud Ahmed',
            title2:'Director,Swapna Blood Donation and Social Welfare Organization',
            desc2:"My journey of working with Murad has been wonderful.I thank him for helping us by making such wonderful Android apps and congratulate him on behalf of our organization and wish him all the best.",
        },
        {
            image:team3,
            name:'Kamrul Hamid',
            title:'C.E.O (Modhuful Food Products LTD)',
            desc:"We have been working with Murad since 2015. Over these last few years, our experience with them has been really great. Murad has always been a huge creative support for us,alos Thanks to LWM Team",
            image2:team4,
            name2:'Mohammed Fasi',
            title2:'Manager,UniverCell Danforth - Buy | Sell | Repair(Toronto, ON M4C 1L7',
            desc2:"While learnwithmurad team's did a great job on the technical aspects of my business.hey built a custom flyers for my shop that perfectly captures our brand and message.thanks to murad",
        },
        {
            image:team5,
            name:'Mohsin Kamal',
            title:'Manager, Halalhutbd',
            desc:" This is what we actually needed, an up to the mark web developers & web maintenance specialists. Very reliable and efficient group of people to work with, i wish them all the best for LWM Team.",
            image2:team6,
            name2:'Syed Mohsin Reza',
            title2:'C.E.O(Digi Mobile)Spain',
            desc2:" I hired Murad to create a website for my business, and I couldn't be happier with the result! They were professional, responsive, and delivered a high-quality website that exceeded my expectations",
        },
    ]
    return(
        <>
        <div className="container relative lg:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">Awesome Client's Feedback</h3>

                    <p className="text-slate-400 dark:text-white/60 max-w-xl mx-auto">Client's are always expressed their love for Learn with Murad.</p>
                </div>

                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {teamData.map((item,index) =>{
                        return(
                            <ul className="space-y-6" key={index}>
                            <li className="rounded-xl shadow dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={item.image} className="h-14 w-14 rounded-full shadow dark:shadow-gray-800" alt=""/>
    
                                    <div className="ps-3">
                                        <Link to="" className="text-[16px] font-medium hover:text-orange-500 duration-500">{item.name}</Link>
                                        <p className="text-slate-400 dark:text-white/60">{item.title}</p>
                                    </div>
                                </div>
    
                                <div className="mt-6">
                                    <p className="text-slate-400 dark:text-white/60">{item.desc}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
    
                            <li className="rounded-xl shadow dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={item.image2} className="h-14 w-14 rounded-full shadow dark:shadow-gray-800" alt=""/>
    
                                    <div className="ps-3">
                                        <Link to="" className="text-[16px] font-medium hover:text-orange-500 duration-500">{item.name2}</Link>
                                        <p className="text-slate-400 dark:text-white/60">{item.title2}</p>
                                    </div>
                                </div>
    
                                <div className="mt-6">
                                    <p className="text-slate-400 dark:text-white/60">{item.desc2}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        )
                    })}
                </div>
            </div> 
        </>
    )
}