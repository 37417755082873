import blog1 from '../assets/images/blog/1.jpg'
import blog2 from '../assets/images/blog/2.jpg'
import blog3 from '../assets/images/blog/3.jpg'
import blog4 from '../assets/images/blog/4.jpg'
import blog5 from '../assets/images/blog/5.jpg'
import blog6 from '../assets/images/blog/6.jpg'
import blog7 from '../assets/images/blog/7.jpg'
import blog8 from '../assets/images/blog/8.jpg'
import blog9 from '../assets/images/blog/9.jpg'
import team1 from '../assets/images/team/murad_ceo.jpg'
import team2 from '../assets/images/team/02.jpg'
import team3 from '../assets/images/team/03.jpg'
import team4 from '../assets/images/team/04.jpg'
import team5 from '../assets/images/team/05.jpg'
import team6 from '../assets/images/team/06.jpg'
import team7 from '../assets/images/team/07.jpg'
import team8 from '../assets/images/team/08.jpg'

export const blogData = [
    {
        id:1,
        image:blog1,
        title:'Modhuful Food Production Ltd',
        athor:'Murad',
        image1:team1,
        date:"30th June 2016",
        desc:"Modhuful Food Production Ltd || Client- Kamrul Hamid",
        desc02:"A Complete E-Commerce Project."
    },
    {
        id:2,
        image:blog2,
        title:'React JS E-Commerce Project For Halalhutbd',
        athor:'Murad',
        image1:team1,
        date:"29th November 2023",
        desc:"React JS E-Commerce Project For Halalhutbd || Client - Mohsin Kamal"
    },
    {
        id:3,
        image:blog3,
        title:'Android App Development',
        athor:'Murad',
        image1:team1,
        date:"19th May 2018",
        desc:"SRSS Blood Donor finder Android App for Swapna Blood Donation and Social Welfare Organization || Client - Masud Ahmed"
    },
    {
        id:4,
        image:blog4,
        title:'Mobile Station Bd(A Trusted SmartPhone Store)',
        athor:'Murad',
        image1:team1,
        date:"1st January 2023",
        desc:" MobileStationbd (A Trusted Mobile Phone Shop) || Client - Murad Hussain & Rahmat Ali "
    },
    {
        id:5,
        image:blog5,
        title:' Cari de linda-Indian restaurant,Flayer Design)',
        athor:'Murad',
        image1:team1,
        date:"19th July 2024",
        desc:" Cari de linda-Indian restaurant, Québec,QC G1H 2S9) || Client - Kawsar Ahmed"
    },
    {
        id:6,
        image:blog6,
        title:' UniverCell Danforth - Flayer Design',
        athor:'Murad',
        image1:team1,
        date:"20th September 2024",
        desc:"UniverCell Danforth - Buy | Sell | Repair, Flayer Design || Client - Mohammed Fasi "
    },
    {
        id:7,
        image:blog7,
        title:'Digi Mobile || Spain',
        athor:'Murad',
        image1:team1,
        date:"1st December 2024",
        desc:"Digi Mobile)Spain || Client - Syed Mohsin Reza"
    },
   
]