import React,{useState} from "react";
import { Link, useParams } from "react-router-dom";

import team1 from "../assets/images/team/01.jpg"
import blog from "../assets/images/blog/4.jpg"
import blog2 from "../assets/images/blog/5.jpg"

import Navbar from '../components/navbar'
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import ContactUs from "../components/contactUs"

import { blogData } from "../data/data";

import ModalVideo from 'react-modal-video';
import "../../node_modules/react-modal-video/css/modal-video.css"

export default function BlogDetails(){
    const params = useParams()
    const id = params.id
    const data = blogData.find((blog)=>blog.id === parseInt(id))
    const [isOpen, setOpen] = useState(false);
    return(
        <>
        <Navbar/>
        <section className="relative pt-40 md:pb-24 pb-16">
            <div className="container relative">
                <div className="md:flex justify-center">
                    <div className="lg:w-3/5">
                        <h5 className="md:text-3xl text-2xl md:leading-normal leading-normal font-medium">{data?.title ? data?.title : " "}</h5>
                        
    
                        <div className="flex mt-5">
                            <span className="font-medium"><i className="mdi mdi-calendar-blank-outline me-2"></i>{data?.date ? data?.date : "19th June, 2023"}</span>
                            {/* <span className="ms-3 font-medium"><i className="mdi mdi-clock-outline me-2"></i>5 min read</span> */}
                        </div>

                

                        <div className="flex items-center mt-5">
                            <img src={data?.image1 ? data?.image1 : team1} className="h-12 w-12 rounded-xl" alt=""/>

                            <div className="ms-2">
                                <Link to="" className="font-medium block hover:text-orange-500">{data?.athor ? data?.athor : "Calvin Carlo"}</Link>
                                <span className="text-slate-400 dark:text-white/60 text-sm block">www.learnwithmurad.com</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="md:flex justify-center mt-6">
                    <div className="lg:w-full">
                        <div className="relative">
                            <img src={data?.image ? data?.image : blog} className="rounded-xl shadow-md dark:shadow-gray-800" alt=""/>
                            <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                <Link to="#!" onClick={() => setOpen(true)} className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-orange-600">
                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </Link>
                            </div>
                                {/* <ModalVideo
                                    channel="youtube"
                                    youtube={{ mute: 0, autoplay: 0 }}
                                    isOpen={isOpen}
                                    videoId="#"
                                    onClose={() => setOpen(false)} 
                                /> */}
                        </div>
                    </div>
                </div>


                <div className="md:flex justify-center mt-6">
                    <div className="lg:w-3/5">
                        <p className=" {data?.image1 ? data?.image1 : team1} text-slate-400 dark:text-white/60 text-[15px]">
                        Hi! Welcome to learnwithmurad, a creative startup platform providing specializing in accelerating your business growth through innovative digital strategies and cutting-edge marketing campaigns. </p>
                    </div>
                </div>
                <div className="md:flex justify-center mt-6">
                    <div className="lg:w-3/5">
                        <img src={data?.image ? data?.image : blog} className="rounded-xl mt-4" alt=""/>
                        <p className="text-slate-400 dark:text-white/60 text-[15px] mt-4"> Learnwith murad will present latest technology news, programming related video tutorials, website design and development and software troubleshooting services.with our comprehensive range of services we empower your brand to thrive in the digital landscape and reach new heights of success.</p>
                    </div>
                </div>
            </div>

           <ContactUs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}