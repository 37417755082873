import React from "react";
import { Link } from "react-router-dom";

import icon1 from "../assets/images/icons/Web Design_01.svg"
import icon2 from "../assets/images/icons/E-commerce Solutions_01.svg"
import icon3 from "../assets/images/icons/Mobile App Design_01.svg"
import icon4 from "../assets/images/icons/Social Media_Graphic Designe_01.svg"
import icon5 from "../assets/images/icons/Social Media_Graphic Designe_01.svg"
import icon6 from "../assets/images/icons/Digital_Content Create_01.svg"

import {FiHexagon} from '../assets/icons/vander'

export default function Features({title}){
    const featuresData =[
        {
            image:icon1,
            title:'Web Design',
            desc:"Customized website design and development services that align with your brand goals."
          },
          {
              image:icon2,
              title:'E-commerce Solutions',
              desc:"Build and optimize your online store for seamless and successful e-commerce operation."
            },
             {
            image:icon3,
            title:'Mobile App Design',
            desc:"Create innovative and user-friendly mobile applications to enhance customer experiences and usability."
          }, {
              image:icon4,
              title:'Social Media||Graphic Designe',
              desc:"Expert management and optimization for your company's social media channels and flyers designe."
            },
            {
              image:icon5,
              title:'SEO Optimize',
              desc:"Online reviews and ratings affect your rankings in local searches."
            },
            {
              image:icon6,
              title:'Digital Content Create',
              desc:"Professional Digital Content services to Create and engage your audience while effectively conveying your brand's message."
            },

    ]
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
            {title === false ? "" : 
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-6 md:text-2xl text-xl font-medium">What we do?</h3>

                    <p className="text-slate-400 dark:text-white/60 max-w-xl mx-auto"> Welcome to learnwithmurad, a creative startup platform providing specializing in accelerating your business growth through innovative digital strategies and cutting-edge marketing campaigns.  here you will find latest technology news, programming related video tutorials, website design and development and software troubleshooting services.with our comprehensive range of services we empower your brand to thrive in the digital landscape and reach new heights of success.</p>
                    </div>
            }

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                   {featuresData.map((item, index) =>{
                    return(
                    <div className="group relative lg:px-6 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center" key={index}>
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon className="h-28 w-28 fill-orange-500/5 mx-auto rotate-[30deg]"/>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-orange-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                <img src={item.image} className="h-8" alt=""/>
                            </div>
                        </div>

                        <div className="mt-6">
                            <Link to="/services" className="text-lg font-medium duration-500 hover:text-orange-500">{item.title}</Link>
                            <p className="text-slate-400 dark:text-white/60 text-[15px] duration-500 mt-3">{item.desc}</p>
                        </div>
                    </div>
                    )
                   })}
                    
                   
                </div>
            </div>
        </>
    )
}